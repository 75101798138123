import { ref } from 'vue'
import axios from 'axios'

const useApi = () => {
  const userKey = import.meta.env.VITE_SOAP_USER_KEY
  const loading = ref(false)

  const fetchIP = async () => {
    try {
      const response = await axios.get('/api/get-ip');
      return response.data.ip;
    } catch (error) {
      return null;
    }
  };

  const login = async () => {
    return await axios
      .post('/api/soap/login', {
        userKey: userKey,
      })
      .then((response) => {
        return response.data.data.sid
      })
  }

  const search = async (nip) => {
    loading.value = true
    const sid = await login().then((response) => response)

    return await axios
      .post('/api/soap/search', {
        nip: nip,
        sid: sid,
      })
      .then((response) => {
        loading.value = false
        return response
      })
  }

  const sendRmkt = async (customIdentifier, utmParameters, fields) => {
    loading.value = true

    return await axios
      .post('/api/leadwise/rmkt', {
        customIdentifier: customIdentifier,
        utm_source: utmParameters.utm_source,
        utm_medium: utmParameters.utm_medium,
        utm_campaign: utmParameters.utm_campaign,
        utm_content: utmParameters.utm_content,
        utm_term: utmParameters.utm_term,
        gclid: utmParameters.gclid,
        client_id: utmParameters.client_id,
        IP: await fetchIP(),
        userAgent: utmParameters.userAgent,
        fields: fields,
      })
      .then((response) => {
        loading.value = false
        if (response.data.status === 422) {
          return response.data.data
        }

        if (response.data.data.status === 'Success') {
          return true
        }

        return false
      })
  }

  const sendData = async (customIdentifier, utmParameters, fields) => {
    loading.value = true

    return await axios
      .post('/api/leadwise/data', {
        customIdentifier: customIdentifier,
        utm_source: utmParameters.utm_source,
        utm_medium: utmParameters.utm_medium,
        utm_campaign: utmParameters.utm_campaign,
        utm_content: utmParameters.utm_content,
        utm_term: utmParameters.utm_term,
        gclid: utmParameters.gclid,
        client_id: utmParameters.client_id,
        IP: await fetchIP(),
        userAgent: utmParameters.userAgent,
        fields: fields,
      })
      .then((response) => {
        loading.value = false
        return response
      })
  }

  return { loading, search, sendRmkt, sendData, fetchIP }
}

export default useApi